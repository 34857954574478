import React, { useContext, useRef } from "react";
import sLogoai from "../../assets/images/logo_ai.png";
import { BiUpArrowCircle } from "react-icons/bi";
import { permissionsMap } from "constants/constant";
import { AuthContext } from "contexts/authContext";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { onUpgradeModalOpen } from "redux/store/billing/action";
import { getLogoUrl } from "utils/transport";

const NotAllowedInPlan = () => {
  const dispatch = useDispatch();
  const {
    checkRequiredPermissions,
    coreApiUrl,
    setCookies,
    checkRequiredAccess,
  } = useContext(AuthContext);
  const logoRef = useRef(null);

  const onLogoError = () => {
    setCookies();
    if (logoRef.current) {
      logoRef.current.src = sLogoai;
    }
  };

  const onUpgradePlan = () => {
    dispatch(onUpgradeModalOpen());
  };
  return (
    <center>
      <div className="auth_layout">
        <div
          className="signup_blcok setpass_blcok"
          style={{ marginTop: "-80px" }}
        >
          <div
            className="s_top"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              border: "0px solid #f00",
            }}
          >
            <img
              ref={logoRef}
              src={
                checkRequiredAccess("whiteLabelling")
                  ? `${getLogoUrl(coreApiUrl)}`
                  : sLogoai
              }
              onError={onLogoError}
            />

            <center style={{ marginTop: "25px" }}>
              <div className="text-lg">
                {!checkRequiredPermissions([
                  permissionsMap.TENANT_OWNER,
                  permissionsMap.TENANT_MANAGER,
                ])
                  ? "Please upgrade your plan to use this feature, Contact your admin"
                  : "Please upgrade your plan to use this feature"}
              </div>
              {
                checkRequiredPermissions([permissionsMap.TENANT_OWNER]) && (
                  // subscriptionsData?.plan?.allow_change && (
                  <Button
                    type="primary"
                    icon={<BiUpArrowCircle />}
                    className="icon_btn mt-8 mb-44"
                    onClick={onUpgradePlan}
                  >
                    Upgrade Plan
                  </Button>
                )
                // )
              }
            </center>
          </div>
        </div>
      </div>
    </center>
  );
};

export default NotAllowedInPlan;
